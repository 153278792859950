import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import JumboPhoto from "../../components/jumbophoto"
import heatherPhoto from "../../images/rsz_1heather-bellew.png"
import { Container, Row, Col } from "react-bootstrap"

export default () => <Layout>
	<Helmet title="Heather Bellew | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>


		<Row>
			<Col>
				<h1>Heather M. Bellew<br/><small className="text-muted">Associate</small></h1>
				<p>Heather Bellew primarily works out of the firm's Blount County office in Oneonta and represents clients from across central and north Alabama in both the Alabama and Federal courts.</p>
				<p>Ms. Bellew is from Blount County, Alabama and holds a B.A. from Jacksonville State University in Political Science. While at Jacksonville State, Ms. Bellew was inducted into Pi Sigma Alpha, the national collegiate honor society for Political Science.</p>
				<p>Ms. Bellew received her Juris Doctor from Samford University Cumberland School of Law, while in law school, Ms. Bellew was the recipient of a Certificate of Merit in Legal Research and Writing.</p>
				<p>Ms. Bellew is a member of the Alabama State Bar and the Birmingham Bar Association.</p>
			</Col>
			<Col sm="4"><img className="img-fluid mb-3" src={heatherPhoto} alt="Heather Bellew" /></Col>
		</Row>
	</Container>
</Layout>